import { useRouter as useNextRouter } from "next/router";
import type React from "react";
import { useEffect, useState } from "react";
import { Link } from "solito/link";
import { useRouter } from "solito/router";

import { Cog as CogIcon, Menu as MenuIcon } from "@tamagui/lucide-icons";

import {
  Adapt,
  Button,
  FullscreenSpinner,
  Popover,
  Separator,
  SizableText,
  type StackProps,
  XStack,
  YStack,
  validToken,
} from "@medbillai/ui";

import { loginUrl, settingsUrl } from "@medbillai/utils/urls";

import { useAuth } from "../../lib/auth";
import { NavTabs } from "./components/NavTabs.web";

export type HomeLayoutProps = {
  children?: React.ReactNode;
  padded?: boolean;
  fullPage?: boolean;
};

export const HomeLayout = ({
  children,
  fullPage = false,
  padded = false,
}: HomeLayoutProps) => {
  const { isReady, isAuthenticated } = useAuth();
  const router = useRouter();

  if (!isReady) {
    return <FullscreenSpinner />;
  }
  if (isReady && !isAuthenticated) {
    router.push(loginUrl());
    return <FullscreenSpinner />;
  }

  return (
    <YStack f={1}>
      <YStack
        gap="$4"
        borderWidth="$0"
        borderBottomColor="$borderColor"
        borderStyle="solid"
        borderBottomWidth="$0.5"
        jc="center"
        px="$4"
        backgroundColor="$color1"
      >
        <XStack jc="space-between" $sm={{ ai: "center" }} ai="flex-end">
          <YStack $sm={{ display: "none" }}>
            <NavTabs orientation="horizontal" size="$4" />
          </YStack>
          <YStack $gtSm={{ display: "none" }}>
            <MobileNavbar>
              <YStack gap="$5" width="100%" alignItems="flex-end">
                <NavTabs orientation="vertical" f={1} width="100%" size="$3" />
                <Separator width="100%" />
                <Separator width="100%" />
                {isAuthenticated && (
                  <WithUserDetail ai="center" gap="$4">
                    <SettingsButton />
                  </WithUserDetail>
                )}
              </YStack>
            </MobileNavbar>
          </YStack>
          {isAuthenticated && (
            <XStack ai="center" gap="$4" py="$3">
              <SettingsButton />
            </XStack>
          )}
        </XStack>
      </YStack>

      <YStack
        {...(fullPage && { flex: 1 })}
        {...(padded && {
          maxWidth: 800,
          mx: "auto",
          px: "$2",
          width: "100%",
        })}
      >
        {children}
      </YStack>
    </YStack>
  );
};

export const MobileNavbar = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const router = useNextRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, setOpen]);
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      size="$5"
      stayInFrame={{ padding: 20 }}
    >
      <Popover.Trigger asChild>
        <Button
          chromeless
          p="$2"
          onPress={() => setOpen(!open)}
          theme={open ? "alt1" : null}
          icon={<MenuIcon size={32} />}
        />
      </Popover.Trigger>

      <Adapt platform="web" when="sm">
        <Popover.Sheet zIndex={100000000} modal dismissOnSnapToBottom>
          <Popover.Sheet.Frame>
            <Popover.Sheet.ScrollView>
              <Adapt.Contents />
            </Popover.Sheet.ScrollView>
          </Popover.Sheet.Frame>
          <Popover.Sheet.Overlay zIndex={100} />
        </Popover.Sheet>
      </Adapt>

      <Popover.Content
        bw={1}
        boc="$borderColor"
        enterStyle={{ x: 0, y: -10, o: 0 }}
        exitStyle={{ x: 0, y: -10, o: 0 }}
        x={0}
        y={0}
        o={1}
        animation={[
          "quick",
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
        p={0}
        maxHeight={validToken("80vh")}
        elevate
        zIndex={100000000}
      >
        <Popover.Arrow borderWidth={1} boc="$borderColor" />

        <Popover.ScrollView
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
        >
          <YStack
            miw={230}
            p="$3"
            ai="flex-end"
            // display={open ? 'flex' : 'none'}
          >
            {children}
          </YStack>
        </Popover.ScrollView>
      </Popover.Content>
    </Popover>
  );
};

const SettingsButton = () => (
  <Link href={settingsUrl()}>
    <CogIcon size={32} />
  </Link>
);

const WithUserDetail = ({ children, ...props }: StackProps) => {
  const { user } = useAuth();
  return (
    <XStack gap="$2" {...props}>
      <YStack ai="flex-end">
        <SizableText theme="alt1">{user?.profile?.email}</SizableText>
      </YStack>
      {children}
    </XStack>
  );
};
