import { useRouter } from "next/router";
import type React from "react";

import { useAuth } from "@medbillai/app/lib/auth";

import { FullscreenSpinner } from "@medbillai/ui";

import { loginUrl } from "@medbillai/utils/urls";

export const AuthGate = (props: { children: React.ReactNode }) => {
  const { user, isReady, isAuthenticated } = useAuth();
  const { replace } = useRouter();

  if (!isReady) {
    return <FullscreenSpinner />;
  }

  if (!isAuthenticated) {
    void replace(loginUrl({ forceLogout: true }));
    return <FullscreenSpinner />;
  }

  // We only expect admins or shadow logins at the moment on web
  if (!user?.isAdmin && !user?.isShadow) {
    void replace(loginUrl({ forceLogout: true }));
    return <FullscreenSpinner />;
  }

  return <>{props.children}</>;
};
