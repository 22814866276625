import {
  Anchor as AnchorOG,
  Heading,
  Label as LabelOG,
  Paragraph as ParagraphOG,
  SizableText as SizableTextOG,
  Text as TextOG,
  styled,
} from "tamagui";

import { subheadlineLineHeight } from "../config/fonts";

const commonVariants = {
  bold: {
    true: {
      fontWeight: "500",
    },
  },
  italic: {
    true: {
      fontStyle: "italic",
    },
  },
} as const;

// Core typography components.
export const Text = styled(TextOG, {
  variants: {
    ...commonVariants,
  } as const,
});

export const SizableText = styled(SizableTextOG, {
  variants: {
    ...commonVariants,
  } as const,
});

// Headlines.
export const Headline = styled(Heading, {
  name: "Headline",
  tag: "p",
  size: "$9",
  variants: {
    ...commonVariants,
  } as const,
});

export const Headline1 = styled(Heading, {
  name: "Headline1",
  tag: "h1",
  fontFamily: "$heading",
  size: "$11",
});

// Subheadlines override font weight and line height from a regular Paragraph,
// so we need to provide specific components for them.
export const Subheadline = styled(ParagraphOG, {
  name: "Subheadline",
  tag: "p",
  // Subheadlines are Light by default.
  fontWeight: "300",

  // We need to override line height from the default Paragraph component.
  variants: {
    ...commonVariants,
    size: {
      "...size": (val, { font }) => {
        return {
          // @ts-expect-error Tamagui types aren't great here
          fontSize: font?.size?.[val]?.val as number,
          // @ts-expect-error Tamagui types aren't great here
          lineHeight: font?.size?.[val]?.val * subheadlineLineHeight,
        };
      },
    },
  } as const,

  defaultVariants: {
    size: "$9",
  },
});

export const Subheadline1 = styled(Subheadline, {
  name: "Subheadline1",
  // Masquerade as a heading tag
  tag: "h2",
  size: "$10",
});

export const Subheadline2 = styled(Subheadline, {
  name: "Subheadline2",
  tag: "h3",
  size: "$9",
});

export const Subheadline3 = styled(Subheadline, {
  name: "Subheadline3",
  tag: "h4",
  size: "$8",
});

export const Subheadline4 = styled(Subheadline, {
  name: "Subheadline4",
  tag: "h5",
  size: "$7",
});

export const Subheadline5 = styled(Subheadline, {
  name: "Subheadline5",
  size: "$6",
  fontWeight: "400",
});

// Regular body text are just different sizes of the Tamagui Paragraph builtin
// tag.
export const Paragraph = styled(ParagraphOG, {
  name: "Paragraph",
  tag: "p",
  variants: {
    ...commonVariants,
  } as const,
});

export const Title = styled(Paragraph, {
  name: "Title",
  size: "$5",
});

export const Paragraph2 = styled(Paragraph, {
  name: "Paragraph2",
  size: "$3",
});

export const ParagraphSmall = styled(Paragraph, {
  name: "ParagraphSmall",
  size: "$2",
});

// Styleable versions of standard components with our variants.
// There are certain components where we want to enable our standard bold and
// italic variants, so we re-export them here.
// TODO: Figure out a good way of achieving this for composite components e.g.
// Button, where we want to style Button.Text.
export const Label = styled(LabelOG, {
  variants: {
    ...commonVariants,
  } as const,
});

export const Anchor = styled(AnchorOG, {
  variants: {
    ...commonVariants,
  } as const,
});
