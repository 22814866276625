import { Image, type StackProps, XStack } from "@medbillai/ui";

export const BackgroundRadialGrantedGradient = (props: StackProps) => {
  return (
    <XStack
      top={0}
      left={0}
      right={0}
      position="absolute"
      width="100%"
      height="50%"
      flex={1}
      {...props}
    >
      <Image
        source={{
          uri: "/static/backgroundGradientFullscreen.png",
        }}
        height="100%"
        width="100%"
        resizeMethod="scale"
      />
    </XStack>
  );
};
